// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-template-js": () => import("./../../../src/pages/template.js" /* webpackChunkName: "component---src-pages-template-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/Thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-time-tracker-integrations-js": () => import("./../../../src/pages/time-tracker-integrations.js" /* webpackChunkName: "component---src-pages-time-tracker-integrations-js" */),
  "component---src-pages-time-tracking-asana-js": () => import("./../../../src/pages/time-tracking-asana.js" /* webpackChunkName: "component---src-pages-time-tracking-asana-js" */),
  "component---src-pages-time-tracking-clickup-js": () => import("./../../../src/pages/time-tracking-clickup.js" /* webpackChunkName: "component---src-pages-time-tracking-clickup-js" */),
  "component---src-pages-time-tracking-github-js": () => import("./../../../src/pages/time-tracking-github.js" /* webpackChunkName: "component---src-pages-time-tracking-github-js" */),
  "component---src-pages-time-tracking-gitlab-js": () => import("./../../../src/pages/time-tracking-gitlab.js" /* webpackChunkName: "component---src-pages-time-tracking-gitlab-js" */),
  "component---src-pages-time-tracking-jira-js": () => import("./../../../src/pages/time-tracking-jira.js" /* webpackChunkName: "component---src-pages-time-tracking-jira-js" */),
  "component---src-pages-time-tracking-redbooth-js": () => import("./../../../src/pages/time-tracking-redbooth.js" /* webpackChunkName: "component---src-pages-time-tracking-redbooth-js" */),
  "component---src-pages-time-tracking-trello-js": () => import("./../../../src/pages/time-tracking-trello.js" /* webpackChunkName: "component---src-pages-time-tracking-trello-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

